<!-- Navigation-->
<div class="AdminSidemenu" id="AdminSidemenu">
    <!-- <nav class="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav"> -->

    <div (click)="switchsidenavtoggled()" class="sidenavToggler">
        <div id="sidenavToggler" class="cursorpointer">
            <i class="far fa-chevron-left"></i>
        </div>
    </div>


    <a class="admin_logo" [routerLink]="['/admin/dashboard']">
        <img src="../../assets/logo.svg">
    </a>

    <!-- <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
    data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false"
    aria-label="Toggle navigation">
    <img src="../../assets/new/responsive_toggle.svg" />
  </button> -->

    <!-- <div class="collapse navbar-collapse" id="navbarResponsive"> -->


    <!-- <div data-toggle="tooltip" data-placement="right" title="Start Applications" class="mb_34 fullsidebar"
        [routerLink]="['/admin/start-application']" *ngIf="pages['Start Application']">
        <button class="btn main_btn w100per" [routerLinkActive]="['active']">
          <span class="nav-link-text">Start Applications</span>
          <i class="fal fa-angle-right"></i>
        </button>
      </div> -->

    <!-- small sidebar menu  start-->

    <div data-toggle="tooltip" data-placement="right" title="Start Applications" class="mb_34 smallsidebar" [routerLink]="['/admin/start-application']" *ngIf="pages['Start Application']">
        <button class="startapplication_btn" [routerLinkActive]="['active']">
        <i class="fa fa-plus-circle" aria-hidden="true"></i>

        
        </button>
    </div>

    <!-- small sidebar menu end -->
    <ul id="exampleAccordion">

        <li data-toggle="tooltip" data-placement="right" title="Dashboard" [routerLink]="['/admin/dashboard']" *ngIf="pages['Dashboard']">
            <a [routerLinkActive]="['active']">
                <img class="sidebar_icon" src="../../assets/new/dashboard.svg" />
                <span class="nav-link-text">Dashboard</span>
            </a>
        </li>

        <li data-toggle="tooltip" data-placement="right" title="Incomplete Applications" [routerLink]="['/admin/loan-stages/waiting']" *ngIf="pages['Incomplete Application']">
            <a [routerLinkActive]="['active']">
                <img class="sidebar_icon" src="../../assets/new/incomplete.svg" />
                <span class="nav-link-text">Incomplete Applications</span>
            </a>
        </li>
        <li data-toggle="tooltip" data-placement="right" title="Pending Applications" [routerLink]="['/admin/loan-stages/pending']" *ngIf="pages['Pending Application']">
            <a [routerLinkActive]="['active']">
                <img class="sidebar_icon" src="../../assets/new/pending.svg" />
                <span class="nav-link-text">Ready to Underwrite</span>
            </a>
        </li>

        <li data-toggle="tooltip" data-placement="right" title="Pending Applications" [routerLink]="['/admin/loan-stages/needsreview']" *ngIf="pages['Pending Application']">
            <a [routerLinkActive]="['active']">
                <img class="sidebar_icon" src="../../assets/new/needs_review.svg" />
                <span class="nav-link-text">Needs Review</span>
            </a>
        </li>


        <li class="nav-item" data-toggle="tooltip" data-placement="right" title="Approved Applications" [routerLink]="['/admin/loan-stages/approved']" *ngIf="pages['Approved Application']">
            <a class="nav-link" [routerLinkActive]="['active']">
                <img class="sidebar_icon" src="../../assets/new/approved.svg" />
                <span class="nav-link-text">Approved </span>
            </a>
        </li>
        <li data-toggle="tooltip" data-placement="right" title="Denied Applications" [routerLink]="['/admin/loan-stages/denied']" *ngIf="pages['Denied Application']">
            <a [routerLinkActive]="['active']">
                <img class="sidebar_icon" src="../../assets/new/denied.svg" />
                <span class="nav-link-text">Denied </span>
            </a>
        </li>
        <li data-toggle="tooltip" data-placement="right" title="Canceled Applications" [routerLink]="['/admin/loan-stages/canceled']" *ngIf="pages['Denied Application']">
            <a [routerLinkActive]="['active']">
                <!-- <i class="fa fa-ban sidebar_icon" aria-hidden="true"></i> -->
                <img class="sidebar_icon" src="../../assets/new/cancel_icon.svg" />
                <span class="nav-link-text">Cancelled </span>
            </a>
        </li>
        <li data-toggle="tooltip" data-placement="right" title="Expired Applications" [routerLink]="['/admin/loan-stages/expired']" *ngIf="pages['Denied Application']">
            <a [routerLinkActive]="['active']">
                <img class="sidebar_icon" src="../../assets/new/icons_expired.svg" />
                <span class="nav-link-text">Expired </span>
            </a>
        </li>
        <li data-toggle="tooltip" data-placement="right" title="Denied Applications" [routerLink]="['/admin/denied']" *ngIf="pages['Denied Application']">
            <a [routerLinkActive]="['active']">
                <img class="sidebar_icon" src="../../assets/new/error_icon.svg" />
                <span class="nav-link-text">Error </span>
            </a>
        </li>
        <li data-toggle="tooltip" data-placement="right" title="Denied Applications" [routerLink]="['/admin/denied']" *ngIf="pages['Denied Application']">
            <li data-toggle="tooltip" data-placement="right" title="Denied Applications" [routerLink]="['/admin/loan-stages/active']" *ngIf="pages['Approved Application']">
                <a [routerLinkActive]="['active']">
                    <img class="sidebar_icon" src="../../assets/new/loan_active_icon.svg">
                    <span class="nav-link-text">All Active Loans </span>
                </a>
            </li>
            <li data-toggle="tooltip" data-placement="right" title="Denied Applications" [routerLink]="['/admin/denied']" *ngIf="pages['Denied Application']">
                <a [routerLinkActive]="['active']">
                    <img class="sidebar_icon" src="../../assets/new/up_to_date.svg">
                    <span class="nav-link-text">Up-To-Date Loans </span>
                </a>
            </li>

            <li data-toggle="tooltip" data-placement="right" title="Bankruptcy" [routerLink]="['/admin/bankruptcy']">
                <a [routerLinkActive]="['active']">
                    <img class="sidebar_icon" src="../../assets/new/hardship.svg" />
                    <span class="nav-link-text">Hardship </span>
                </a>
            </li>

            <li data-toggle="tooltip" data-placement="right" title="Bankruptcy" [routerLink]="['/admin/bankruptcy']">
                <a [routerLinkActive]="['active']">
                    <img class="sidebar_icon" src="../../assets/new/bankruptcy_icon.svg" />
                    <span class="nav-link-text">Bankruptcy </span>
                </a>
            </li>

            <li data-toggle="tooltip" data-placement="right" title="Bankruptcy" [routerLink]="['/admin/bankruptcy']">
                <a [routerLinkActive]="['active']">
                    <img class="sidebar_icon" src="../../assets/new/written_off.svg">
                    <span class="nav-link-text">Written Off </span>
                </a>
            </li>

            <li data-toggle="tooltip" data-placement="right" title="Bankruptcy" [routerLink]="['/admin/bankruptcy']">
                <a [routerLinkActive]="['active']">
                    <img class="sidebar_icon" src="../../assets/new/fraud_icon.svg">
                    <span class="nav-link-text">Fraud </span>
                </a>
            </li>
            <li data-toggle="tooltip" data-placement="right" title="Bankruptcy" [routerLink]="['/admin/bankruptcy']">
                <a [routerLinkActive]="['active']">
                    <img class="sidebar_icon" src="../../assets/new/complaint_icon.svg">
                    <span class="nav-link-text">Complaint </span>
                </a>
            </li>
            <li data-toggle="tooltip" data-placement="right" title="Archived Open Applications" *ngIf="pages['Archived Open Applications']">
                <a>
                    <img class="sidebar_icon" src="../../assets/new/archived.svg" />
                    <span class="nav-link-text">Archived</span>
                </a>
            </li>
            <li data-toggle="tooltip" data-placement="right" title="Collections" [routerLink]="['/admin/loan-stages/collections']">
              <a>
                  <img class="sidebar_icon" src="../../assets/new/collection_icon.svg" />
                  <span class="nav-link-text">Collections</span>
              </a>
          </li>
            <!-- <li
        class="nav-item"
        data-toggle="tooltip"
        data-placement="right"
        title="Funding Contracts"
        [routerLink]="['/admin/loan-stages/fundingcontract']"
        *ngIf="pages['Funding Contracts']"
      >
        <a class="nav-link" [routerLinkActive]="['active']">
          <i class="fa fa-fw fa-folder"></i>
          <img src="../../assets/new/funted_contract.png">
          <span class="nav-link-text">Funding Contracts</span>
        </a>
      </li> -->
            <!-- <li
        class="nav-item"
        data-toggle="tooltip"
        data-placement="right"
        title="Performing Contracts"
        [routerLink]="['/admin/loan-stages/performingcontract']"
      >
        <a class="nav-link" [routerLinkActive]="['active']">
          <img class="sidebar_icon" src="../../assets/new/performing.svg">
          <span class="nav-link-text">Performing Contracts</span>
        </a>
      </li> -->
            <!-- <li class="nav-item" data-toggle="tooltip" data-placement="right" title="Collections">
                <a class="nav-link nav-link-collapse collapsed" [routerLinkActive]="['active']" (click)="CollectionsSettings()" [class.collapsed]="!Collections" data-toggle="collapse" data-parent="#exampleAccordion">
                    <img class="sidebar_icon" src="../../assets/new/collection_icon.svg">
                    <span class="nav-link-text">Collections</span>
                    <i class="fal fa-angle-right ml-auto fontsize_15 font_wight_400"></i>
                </a>
            </li> -->
            <!-- <ul class="sidenav-second-level collapse" [class.show]="Collections" id="collapseComponents">
                <li class="nav-item" [routerLink]="['/admin/collections/all']">
                    <a class="nav-link">
                        <img class="sidebar_icon" src="../../assets/new/all_collection_icon.svg">
                        <span class="nav-link-text">All Collections</span></a>
                </li>

                <li class="nav-item" [routerLink]="['/admin/collections/pending']">
                    <a class="nav-link">
                        <img class="sidebar_icon" src="../../assets/new/icon_pending.svg">
                        <span class="nav-link-text">Pending Collections</span></a>
                </li>

                <li class="nav-item" [routerLink]="['/admin/collections/mycollections']">
                    <a class="nav-link">
                        <img class="sidebar_icon" src="../../assets/new/show_mycollection.svg">

                        <span class="nav-link-text">Show My Collections</span></a>
                </li>

                <li class="nav-item" [routerLink]="['/admin/collections/promisetopay']">
                    <a class="nav-link">
                        <i class="fal fa-file-search sidebar_icon"></i>
                        <img class="sidebar_icon" src="../../assets/new/pay_icon.svg">
                        <span class="nav-link-text">Show Promise To Pay</span></a>
                </li>

                <li class="nav-item" [routerLink]="['/admin/collections/modifyloans']">
                    <a class="nav-link">
                        <i class="fal fa-file-search sidebar_icon"></i>


                        <img class="sidebar_icon" src="../../assets/new/modified_loan.svg">
                        <span class="nav-link-text">Show Modified Loans</span></a>
                </li>
            </ul> -->

            <li>
                <hr />
            </li>
            <li class="nav-item" data-toggle="tooltip" data-placement="right" title="Components" *ngIf="pages['Settings']">
                <a class="nav-link nav-link-collapse collapsed" [routerLinkActive]="['active']" (click)="switchSettings()" [class.collapsed]="!Settings" data-toggle="collapse" data-parent="#exampleAccordion">
                    <!-- <i class="fa fa-fw fa-th sidebar_icon"></i> -->
                    <img class="sidebar_icon" src="../../assets/new/setting_icon.svg">

                    <span class="nav-link-text">Settings</span>
                    <i class="fal fa-angle-right ml-auto fontsize_15 font_wight_400"></i>
                </a>
            </li>
            <ul class="sidenav-second-level collapse" [class.show]="Settings" id="collapseComponents">
                <li class="nav-item" [routerLink]="['/admin/settings/auditlog']" *ngIf="tabs['Audit Log']">
                    <a class="nav-link">
                        <i class="fal fa-file-search sidebar_icon"></i>
                        <span class="nav-link-text">Audit Log</span></a>
                </li>
                <!-- <li class="nav-item">
        <a class="nav-link">
          <i class="fa fa-fw fa-user"></i>
          <span class="nav-link-text">Admin Manage Users</span></a
        >
      </li> -->
                <!-- <li
          class="nav-item"
          [routerLink]="['/admin/settings/questions']"
          *ngIf="tabs['Questions']"
        >
          <a class="nav-link" [routerLinkActive]="['active']">
            <i class="fal fa-question-square"></i>
            <span class="nav-link-text">Questions</span></a
          >
        </li> -->
                <li class="nav-item" [routerLink]="['/admin/settings/admin-security']" *ngIf="tabs['Admin Security']">
                    <a class="nav-link" [routerLinkActive]="['active']">
                        <i class="fal fa-key sidebar_icon"></i>
                        <span class="nav-link-text">Admin Security</span></a>
                </li>
                <li class="nav-item" [routerLink]="['/admin/settings/roles']" *ngIf="tabs['Roles']">
                    <a class="nav-link" [routerLinkActive]="['active']">
                        <i class="fal fa-user-tag sidebar_icon"></i>
                        <span class="nav-link-text">Roles</span></a>
                </li>
            </ul>
            <li class="nav-item" [routerLink]="['/admin/login-log']">
                <a class="nav-link" [routerLinkActive]="['active']">
                    <!-- <i class="fal fa-user-circle sidebar_icon"></i> -->
                    <img class="sidebar_icon" src="../../assets/new/login_log.svg">

                    <span class="nav-link-text"> Login Log</span>
                </a>
            </li>
            <!-- <li class="nav-item" data-toggle="tooltip" data-placement="right" title="All Patients">
            <a class="nav-link" >
              <i class="fa fa-fw fa-folder"></i>
              <span class="nav-link-text">All Patients</span>
            </a>
          </li> -->
            <!-- <li
        class="nav-item"
        data-toggle="tooltip"
        data-placement="right"
        title="Practice Management"
        *ngIf="pages['Installer Management']"
      >
        <a class="nav-link">
          <i class="fa fa-fw fa-folder"></i>
          <span class="nav-link-text">Installer management</span>
        </a>
      </li> -->
            <li class="nav-item" [routerLink]="['/admin/users']">
                <a class="nav-link" [routerLinkActive]="['active']" *ngIf="pages['Users']">
                    <img class="sidebar_icon" src="../../assets/new/all_customer.svg" />
                    <span class="nav-link-text"> all customers</span>
                </a>
            </li>
            <li class="nav-item" [routerLink]="['/admin/loan-setting']">
                <a class="nav-link" [routerLinkActive]="['active']" *ngIf="pages['Users']">
                    <img class="sidebar_icon" src="../../assets/new/all_customer.svg" />
                    <span class="nav-link-text"> Loan Setting</span>
                </a>
            </li>
            <!-- <li
        class="nav-item"
        data-toggle="tooltip"
        data-placement="right"
        title="Create Application"
      >
        <a class="nav-link">
          <i class="fa fa-fw fa-folder"></i>
          <span class="nav-link-text">Create Application</span>
        </a>
      </li> -->
    </ul>
    <!-- <ul class="navbar-nav sidenav-toggler" (click)="switchsidenavtoggled()">
      <li class="nav-item">
        <a class="nav-link text-center" id="sidenavToggler">
          <i class="fa fa-fw fa-angle-left"></i>
        </a>
      </li>
    </ul> -->
    <!-- <ul class="navbar-nav ml-auto"> -->
    <!-- <li
        class="nav-item dropdown"
        [class.show]="Messages"
        (click)="switchmessages()"
      >
        <a
          class="nav-link dropdown-toggle mr-lg-2"
          id="messagesDropdown"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i class="fa fa-fw fa-envelope"></i>
          <span class="d-lg-none"
            >Messages
            <span class="badge badge-pill badge-primary">12 New</span>
          </span>
          <span class="indicator text-primary d-none d-lg-block">
            <i class="fa fa-fw fa-circle"></i>
          </span>
        </a>
        <div
          class="dropdown-menu"
          [class.show]="Messages"
          aria-labelledby="messagesDropdown"
        >
          <h6 class="dropdown-header">New Messages:</h6>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item">
            <strong>David Miller</strong>
            <span class="small float-right text-muted">11:21 AM</span>
            <div class="dropdown-message small">
              Hey there! This new version of SB Admin is pretty awesome! These
              messages clip off when they reach the end of the box so they don't
              overflow over to the sides!
            </div>
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item">
            <strong>Jane Smith</strong>
            <span class="small float-right text-muted">11:21 AM</span>
            <div class="dropdown-message small">
              I was wondering if you could meet for an appointment at 3:00
              instead of 4:00. Thanks!
            </div>
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item">
            <strong>John Doe</strong>
            <span class="small float-right text-muted">11:21 AM</span>
            <div class="dropdown-message small">
              I've sent the final files over to you for review. When you're able
              to sign off of them let me know and we can discuss distribution.
            </div>
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item small">View all messages</a>
        </div>
      </li> -->
    <!-- <li
        class="nav-item dropdown"
        [class.show]="Alert"
        (click)="switchalert()"
      >
        <a
          class="nav-link dropdown-toggle mr-lg-2"
          id="alertsDropdown"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i class="fa fa-fw fa-bell"></i>
          <span class="d-lg-none"
            >Alerts
            <span class="badge badge-pill badge-warning">6 New</span>
          </span>
          <span class="indicator text-warning d-none d-lg-block">
            <i class="fa fa-fw fa-circle"></i>
          </span>
        </a>
        <div
          class="dropdown-menu"
          [class.show]="Alert"
          aria-labelledby="alertsDropdown"
        >
          <h6 class="dropdown-header">New Alerts:</h6>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item">
            <span class="text-success">
              <strong>
                <i class="fa fa-long-arrow-up fa-fw"></i>Status Update</strong
              >
            </span>
            <span class="small float-right text-muted">11:21 AM</span>
            <div class="dropdown-message small">
              This is an automated server response message. All systems are
              online.
            </div>
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item">
            <span class="text-danger">
              <strong>
                <i class="fa fa-long-arrow-down fa-fw"></i>Status Update</strong
              >
            </span>
            <span class="small float-right text-muted">11:21 AM</span>
            <div class="dropdown-message small">
              This is an automated server response message. All systems are
              online.
            </div>
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item">
            <span class="text-success">
              <strong>
                <i class="fa fa-long-arrow-up fa-fw"></i>Status Update</strong
              >
            </span>
            <span class="small float-right text-muted">11:21 AM</span>
            <div class="dropdown-message small">
              This is an automated server response message. All systems are
              online.
            </div>
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item small">View all alerts</a>
        </div>
      </li> -->
    <!-- <li class="nav-item">
        <form class="form-inline my-2 my-lg-0 mr-lg-2">
          <div class="input-group">
            <input
              class="form-control"
              type="text"
              placeholder="Search for..."
            />
            <span class="input-group-append">
              <button class="btn btn-primary" type="button">
                <i class="fa fa-search"></i>
              </button>
            </span>
          </div>
        </form>
      </li> -->
    <!-- <li class="nav-item">
        <a class="nav-link" data-toggle="modal" data-target="#exampleModal" (click)="logout()">
          <i class="fa fa-fw fa-sign-out"></i>Logout</a>
      </li> -->
    <!-- </ul> -->
    <!-- </div> -->
    <!-- </nav> -->
</div>