import { AbstractControl, FormControl, FormGroup } from "@angular/forms";

export function AccountNumberValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const accountNumber = control.get('accountNumber');
    const confmAccountNumber = control.get('confmAccountNumber');
    if(accountNumber.pristine || confmAccountNumber.pristine){
        return null;
    }
    return accountNumber && confmAccountNumber && accountNumber.value!=confmAccountNumber.value?
    {'misMatch': true} :
    null;
}

// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}

export function ConfirmPasswordValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const newPasword = control.get('newpw');
    const confirmNewPassword = control.get('cnewpw');
    if(newPasword.pristine || confirmNewPassword.pristine){
        return null;
    }
    return newPasword && confirmNewPassword && newPasword.value!=confirmNewPassword.value?
    {'misMatch': true} :
    null;
}

export function IsAdult(control: FormControl): { [key: string]: Boolean } | null {
    const dob = control.value;

    var today = new Date();
    var birthDate = new Date(dob);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    
    if(age < 18) return { minor: true }
    return null;
}