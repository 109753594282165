import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './account/login/login.component';
import { RegisterComponent } from './account/register/register.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ReviewComponent } from './review/review.component';
const routes: Routes = [
    { path:'account/login', component: LoginComponent },
  	{ path:'account/register', component: RegisterComponent },
  	{ path:'dashboard', component: DashboardComponent },
    { path: "sales", loadChildren: () => import('./sales/sales.module').then(m => m.SalesModule)},
    { path: "borrower", loadChildren: () => import('./borrower/borrower.module').then(m => m.BorrowerModule)},
    { path: "admin", loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)},
    { path: "start-application", loadChildren: () => import('./start-application/start-application.module').then(m => m.StartApplicationModule)},
    { path: 'review/:id', component: ReviewComponent },
  	{ path: '', redirectTo: "start-application/new", pathMatch: 'full' },
    { path: '**', redirectTo: "start-application/new", pathMatch: 'full' },
  ];

@NgModule({
  //imports: [RouterModule.forRoot(routes,{scrollPositionRestoration: 'enabled',useHash: true, relativeLinkResolution: "legacy", })],
  imports: [RouterModule.forRoot(routes,{scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
