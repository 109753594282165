<section class="banner">
    <div class="container">
        <div class="row">
        <div class="col-md-6">
            <div class="banner_content">
                <h2>Affordable Personal Loans up to $55,000</h2>
                <p>Your best solution for a best future</p>
                <button routerLink="/start-application/new" class="btn main_btn">Get Started <i class="fal fa-angle-right"></i></button>
            </div>
        </div>
        <div class="col-md-6">
            <div class="banner_img">
                <img src="assets/new/banner_img.svg">
            </div>
        </div>
        </div>
    </div>
</section>
<section class="inner_ban" style="display:none;">
   <div class="container">
    <div class="media">
        <div class="media-body">
          <h2 class="mt-0">Affordable Personal Loans up to $55,000</h2>
          Your best solution for a best future
        </div>
        <img src="assets/new/banner_img.svg" class="mr-3" alt="...">
      </div>
    </div>
</section>
<section class="borrowing_amount" style="display:none;"> 
    <div class="container">
        <div class="how_much">
            <div class="row">
                <div class="col-md-3">
                     <img src="assets/new/money.svg">
                </div>
                <div class="col-md-7">
                          <!-- fff -->
                          
                            <h3>How much would you like to borrow?</h3>
                            <div class="progressbar">
                                <input type="range" step="100" min="5000" max="55000" id="slider" value="0">
                            </div>
                            <div class="range">
                                <div class="min">$5,000</div>
                                <div class="max">$55,000</div>
                            </div>
                         <button class="btn main_btn">Continue <i class="fal fa-angle-right"></i></button>

                        
                         

                          <!-- ggg -->
                </div>
            </div>
        </div> 
        <div class="how_much">
            <div class="media">
                <img class="mr-3" src="assets/new/get_started.svg" alt="Generic placeholder image">
                <div class="media-body">
                  <h5 class="mt-0">Get Started</h5>
                 <p> See if you're approved in a few easy steps</p>

                 <form>
                     <div class="form-group">
                         <input type="text" class="form-control" placeholder="First Name">
                     </div>
                     <div class="form-group">
                        <input type="text" class="form-control" placeholder="Middle Name">
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Last Name">
                    </div>
                    <div class="form-group">
                        <input type="email" class="form-control" placeholder="Email">
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Mobile Phone">
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="create password">
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="conform password">
                    </div>


                    <button class="btn main_btn">Continue <i class="fal fa-angle-right"></i></button>
                 </form>
                </div>
              </div>
        </div>   

        <div class="how_much">
            <div class="media">
                <img class="mr-3" src="assets/new/get_started.svg" alt="Generic placeholder image">
                <div class="media-body">
                  <h5 class="mt-0">Tell Us About Yourself</h5>
                 <p>This information will help us maximize your loan options</p>

                 <form>
                     <div class="form-group">
                         <input type="text" class="form-control" placeholder="Street Address">
                     </div>
                     <div class="form-group">
                        <input type="text" class="form-control" placeholder="Unit/Appartment">
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="city">
                    </div>
                   <div class="row">
                       <div class="col-md-6">
                        <div class="form-group">
                            <select class="form-control" placeholder="State">
                                <option selected="">Select State</option>
                                <option value="1">state1</option>
                                <option value="2">state2</option>
                             </select>
                        </div>
                       </div>
                       <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Zip Code">
                        </div>
                       </div>
                   </div>

                   <div class="row">
                    <div class="col-md-4">
                     <div class="form-group">
                         <select class="form-control" placeholder="Birth Month">
                             <option selected="">Select Birth Month</option>
                             <option value="1">1</option>
                             <option value="2">2</option>
                          </select>
                     </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <select class="form-control" placeholder="Day">
                                <option selected="">Select Day</option>
                                <option value="1">Day 1</option>
                                <option value="1">Day 2</option>
                             </select>
                        </div>
                       </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <select class="form-control" placeholder="Year">
                                <option selected="">Select Year</option>
                                <option value="1">2021</option>
                                <option value="2">2022</option>
                             </select>
                        </div>
                    </div>
                </div>
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Net Monthly Income">
                    </div>
                    <div class="form-group">
                        <select class="form-control" placeholder="Source of Income">
                            <option selected="">Select Source of Income</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                         </select>
                    </div>
                    <div class="form-group">
                        <select class="form-control" placeholder="Pay Frequency">
                            <option selected="">Select Pay Frequency</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                         </select>
                    </div>
                    <div class="form-group">
                        <select class="form-control" placeholder="How do you get paid?">
                            <option selected="">Select  paid</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                         </select>
                    </div>
                    <p>Alimony, child support, or separate maintenance income need not be revealed if you do not wish to have it considered as a basis for repaying the obligation.</p>
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Bank Routing Number">
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Bank Account Number">
                    </div>


                    <button class="btn main_btn">Continue <i class="fal fa-angle-right"></i></button>
                 </form>
                </div>
              </div>
        </div> 




        <!-- ff -->
        <div class="how_much">
            <div class="media">
                <img class="mr-3" src="assets/new/bank_info.svg" alt="Generic placeholder image">
                <div class="media-body">
                  <h5 class="mt-0">Banking information</h5>
                 <p>Please, log in to the bank where you receive your payroll direct deposit or deposit your payroll check for income verification purposes</p>

                 <button class="btn main_btn">Connect Bank </button>
                 <button class="btn main_btn">Manually Submit Banking Information </button>
                </div>
              </div>
        </div>  
    </div>
</section>


<!-- customer portal -->


<section class="customer_portal" style="display:none;">
    <div class="container">
        <div class="row">
            <div class="col-md-3">

            </div>
            <div class="col-md-9">
                
                <div class="customer_portal_content">
                    <div class="media">
                        <img class="mr-3" src="assets/new/profile.svg" alt="Generic placeholder image">
                        <div class="media-body"> 
                          <h5 class="mt-0">Your Profile</h5>
                          <div class="profile_table">
                          <table class="table">
                            <!-- <tbody> -->
                              <tr>
                                 <th>Name :</th>
                                 <td>Temeka Adams</td>
                              </tr>
                              <tr>
                                <th>Address :</th>
                                <td>8180 Briarwood St 8180, California, CA 8180</td>
                              </tr>
                              <tr>
                                <th>Phone :</th>
                                <td>449-698-8331</td>
                              </tr>
                              <tr>
                                <th>Email :</th>
                                <td>temekaadams+12.09@theecode.co</td>
                              </tr>  
                            <!-- </tbody> -->
                          </table>
                          </div>
                        </div>
                      </div>                  
                </div>
            </div>
        </div>
    </div>
</section>


