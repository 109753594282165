import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { NgxSpinnerService } from "ngx-spinner";

const disableLoaderForApis = [
    'autocomplete',
    'addlogs',
    'addloginlogs'
];

@Injectable({ providedIn: "root" })
export class JwtInterceptor implements HttpInterceptor {
    reqQueue: Number[] = [];
    constructor(private spinner: NgxSpinnerService){}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if(!disableLoaderForApis.includes(request.url.split('/').pop())) {
            
            this.spinner.show().then(() => {
                this.reqQueue.push(1);
            });
        }
       
        return next.handle(request)
        .pipe(tap ((result)=>{
        },(error)=>{
            if(error['error']['message']=='Token error: jwt expired'){
                sessionStorage.clear()
                location.reload();
            }
            //console.log(error) 
        }),finalize(()=>{
            this.reqQueue.pop();
            if(this.reqQueue.length == 0) this.spinner.hide().then();
        }));
    }
}