<div class="" style="margin: 0 !important; padding: 0 !important">
  <div
    class="col-12"
    *ngIf="!installersidebar && !adminsidebar && !borrowerSidebar"
    style="margin: 0 !important; padding: 0 !important"
  >
    <router-outlet (activate)="changeOfRoutes()"></router-outlet>
  </div>

  <div class="displayFlex">
    <div *ngIf="installersidebar" class="leftMenu">
      <app-installersidebar></app-installersidebar>
    </div>
    <div *ngIf="installersidebar" class="mainContent">
      <router-outlet (activate)="changeOfRoutes()"></router-outlet>
    </div>
  </div>
  <div class="borrower_sec p50" *ngIf="isBorrowerPortal">
    <div class="container-fluid whitebackground">
      <div class="textAlignCenter headershadow pb20">
        <img src="../../assets/logo.svg" class="w150" />
      </div>

      <div class="row mpadding">
        <div *ngIf="borrowerSidebar" class="col-md-3">
          <app-borrower-sidebar></app-borrower-sidebar>
        </div>
        <div *ngIf="borrowerSidebar" class="col-md-9">
          <router-outlet (activate)="changeOfRoutes()"></router-outlet>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="adminsidebar">
    <admin-sidebar>
      <div
        class="admin_wrapper"
        [class.sidenav-toggled]="sidenavtoggled"
        id="page-top"
      >
        <app-adminsidebar></app-adminsidebar>
        <div class="content-wrapper">
          <div class="top_header displayFlex flexEnd AlignItemCenter">
            <!-- <div (click)="switchsidenavtoggled()" class="sidenavToggler">
    <div id="sidenavToggler " class="cursorpointer">
      <i class="far fa-chevron-left"></i>
    </div>
  </div> -->
            <div>
              <a
                class="logout_btn"
                data-toggle="modal"
                data-target="#exampleModal"
                (click)="logout()"
              >
                <i class="fa fa-fw fa-sign-out"></i>Logout</a
              >
            </div>
          </div>
          <router-outlet (activate)="changeOfRoutes()"></router-outlet>
        </div>
      </div>
    </admin-sidebar>
  </div>
</div>
<ngx-spinner></ngx-spinner>

<ng-template #template>
  <div
    class="modal-body text-center"
    data-backdrop="false"
    data-keyboard="false"
  >
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="close()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
    <p class="headtext">Message</p>
    <p *ngFor="let m of message">{{ m }}</p>
  </div>
</ng-template>
